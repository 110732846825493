// -----------------------------------------------------------------------------
// Forms component's extension
// -----------------------------------------------------------------------------

// Indicate that a form field is required.
.required {
  font-size: 90%;
  color: $danger;
}

.btn-search {
  background-color: $lighter;
}
