// -----------------------------------------------------------------------------
// Application-wide variables
// -----------------------------------------------------------------------------

// Path to fonts and images folders, relative to app.scss
$font-path: '../fonts';
$img-path: '../img';

//
// Bootstrap
//
// see: ../../node_modules/bootstrap/scss/_variables.scss

// Color system
// You could generate a color scheme with: https://palx.jxnblk.com

// couleurs de la maquette
$red: #c00418;
$orange: #ff4b0e;
$yellow: #f7c05b;

$white: #fff;
$lighter: #f2f2f2;
$light: #d7d7d7;
$grey: #999;
$dark: #3c3c3c;
$black: #000;

$primary: $red;
$secondary: $grey;

// Options
$enable-validation-icons: false;

//buttons
$btn-white-space: nowrap;
$btn-focus-box-shadow: none;

//card
$card-bg: transparent;
$card-border-color: $light;
$card-border-width: 3px;
$card-border-radius: 1rem;

// forms
$input-focus-box-shadow: none;
$input-focus-width: 0;
$input-focus-bg: $white;
$input-border-radius: 0;
$input-btn-focus-width: 0;

//nav
$navbar-light-color: $black;
$navbar-light-hover-color: $primary;
$navbar-light-font-weight: bolder;
$nav-link-color: $black;

// fonts
$font-family-sans-serif: 'Open Sans';

// typo
$headings-font-family: $font-family-sans-serif;

$h1-font-size: 3rem;
$h2-font-size: 2.25rem;
$headings-margin-bottom: 1rem;

// player
$player-range-track-height: 2px;
$player-range-thumb-height: 10px;
