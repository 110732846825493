@mixin top-right-corner-pos {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

@mixin bottom-border-primary {
  border-bottom: solid 3px $primary;
}

@mixin square($size) {
  width: $size;
  height: $size;
}
