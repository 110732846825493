// -----------------------------------------------------------------------------
// header component's extension
// -----------------------------------------------------------------------------

.main-header {
  flex-shrink: 0;
  // magick pour la hauteur en attendant qu'outline-offset soit compatible IE...
  height: 235px; // image height
  padding-top: $spacer * 2;
  padding-bottom: $spacer;
  background-color: $black;
  background-repeat: no-repeat;
  background-position: top;
  &-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &-play-button {
    padding: 0;
    margin: 0 5% 0 0;
  }
  &-toggle-button {
    @include top-right-corner-pos;
    z-index: 100;
    background: $white;
  }
}

.main-title,
.main-headline {
  margin-left: $spacer * 2;
}

.main-headline {
  font-size: 1.5rem;
}

/* notre barre de recherche */
.search-bar {
  @include media-breakpoint-up(lg) {
    position: absolute;
    right: 1rem;
    bottom: 0;
  }
}

.search-input-group {
  flex-wrap: nowrap;
  border: 1px solid $light;
}

.search-input {
  background-color: $lighter;
  border: none;
}

/* la loupe de la barre de recherche */
.loupe {
  position: relative;
  right: 2.5rem;
}

/* liseret en dessous de la bannière */
.liseret {
  height: 6px;
  flex-shrink: 0;
  @include bottom-border-primary;
}
