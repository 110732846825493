// -----------------------------------------------------------------------------
// Font faces declarations
// -----------------------------------------------------------------------------

// Open Sans

@use '@fontsource/open-sans/scss/mixins' as OpenSans;

@each $weight in ($font-weight-normal, $font-weight-bold) {
  @include OpenSans.fontFace($weight: $weight, $fontName: 'Open Sans');
}
