// -----------------------------------------------------------------------------
// card component's extension
// -----------------------------------------------------------------------------

.card-header {
  overflow: hidden;
}

.card-title {
  text-transform: uppercase;
}

.card-datetime {
  float: right;
  margin-left: 5px;
}

.card-body {
  position: relative;
}

.card-body:hover > .card-title {
  color: $primary;
}
