.podcast-item {
  margin-bottom: $spacer;
}

.podcast-header {
  position: relative;
  background: transparent;
  &-btn {
    position: relative;
    z-index: 1;
  }
}
