.play-button {
  &:hover {
    transition-timing-function: ease-in-out;
    transition-duration: 0.8s;
    transform: rotate(120deg);
  }
  &-circle {
    fill: $primary;
  }
}

.btn-search {
  border-radius: 0;
}
