.footer {
  flex-shrink: 0;
  margin-top: $spacer;
  font-size: 0.9em;
  color: $white;
  background-color: $primary;

  a {
    color: $white;
  }

  p {
    margin: 0;
  }

  &-top {
    display: flex;
    padding: 2rem 0;
  }

  &-contact {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  &-bottom {
    padding: 1rem 0;
    margin: auto;
    text-align: center;
    background-color: $black;
  }
}
