// Page d'un podcast

.podcast-image-wrapper {
  @include square(480px);
  @include media-breakpoint-down(sm) {
    @include square(340px);
  }
  padding: $spacer;
  background: $light;
  border-radius: $card-border-radius;
}

.podcast-img {
  position: absolute;
  top: 0;
  left: 0;
  &-time {
    right: $spacer;
    padding: 0 0.5em;
    font-size: 1.5em;
    color: $black;
    background-color: $white;
    border: solid 1px $black;
    border-radius: 0.75em;
  }
}

.podcast-img-btn,
.podcast-img-time {
  position: absolute;
  bottom: $spacer;
}
