// menus

.nav-link {
  font-weight: $font-weight-bold;
}

// Menu mobile

.mobile-menu {
  padding: $spacer;
  .nav-link {
    @include bottom-border-primary;
    margin-bottom: 1.33rem;
  }
  .btn-close {
    @include top-right-corner-pos;
  }
}
