// ----------------------------------------------------------------------------
// Fork Awesome
// ----------------------------------------------------------------------------
// see: https://forkaweso.me/

$fa-font-path: '~fork-awesome/fonts';

@import '~fork-awesome/scss/variables';
@import '~fork-awesome/scss/mixins';
@import '~fork-awesome/scss/functions';
@import '~fork-awesome/scss/core';
@import '~fork-awesome/scss/larger';
@import '~fork-awesome/scss/fixed-width';
@import '~fork-awesome/scss/list';
@import '~fork-awesome/scss/bordered-pulled';
@import '~fork-awesome/scss/animated';
@import '~fork-awesome/scss/rotated-flipped';
@import '~fork-awesome/scss/stacked';
@import '~fork-awesome/scss/icons';
@import '~fork-awesome/scss/screen-reader';

// Overwrite @font-face definition to use only woff and woff2 formats.
@font-face {
  font-family: '#{$fa-font-family}';
  src: url('#{$fa-font-path}/forkawesome-webfont.woff?v=#{$fa-version}')
      format('woff'),
    url('#{$fa-font-path}/forkawesome-webfont.woff2?v=#{$fa-version}')
      format('woff2');
}
