/* Sticky footer layout with flexboxes, inspired by https://css-tricks.com/couple-takes-sticky-footer/#there-is-flexbox */

html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1 0 auto;
}
